import React from 'react';
import { Route, Switch } from "react-router-dom"; // <-- New code
import Home from './pages/Home';
import About from './pages/about';
import Intro from './pages/intro';
import Mood from './pages/mood';
import Gallery from './pages/gallery';
import Projet from './pages/projet'
import { AnimatePresence } from 'framer-motion';
import { useLocation } from 'react-router';

const ROUTES = [
    { path: "/", key: "INTRO", exact: true, component: Intro },
    { path: "/mood", key: "MOOD", component: Mood },
    { path: "/home", key: "HOME", component: Home },
    { path: "/gallery", key: "GALLERY", exact: true, component: Gallery },
    { path: "/ok", key: "HOME2", component: RenderRoutes },
    { path: "/gallery/:galleryId", key: "GALLERYID", exact: true, component: Projet },

];


export function RenderRoutes() {
    const location = useLocation();

    return (
        <AnimatePresence //initial={false}
            exitBeforeEnter>
            <Switch location={location} key={location.pathname}>
                {ROUTES.map((route, i) => {
                    return <Route key={route.path} path={route.path} exact={route.exact} render={props => <route.component {...props} routes={route.routes} />} />
                })}
                <Route component={() => <Intro/>} />
            </Switch>
        </AnimatePresence>
    );
}
