import * as THREE from 'three'
import React, { Suspense, useEffect, useState, useRef, useImperativeHandle, forwardRef } from 'react'
import { useFrame } from 'react-three-fiber'
import { PerspectiveCamera, Environment, MeshDistortMaterial, ContactShadows, OrbitControls } from '@react-three/drei'
import { useGLTF } from "@react-three/drei";
import { useSpring } from '@react-spring/core'
import { a } from '@react-spring/three'
import ArmchairGray from './ArmchairGray'
import Nikon from './Nikon'
import Canon from './Canon'
import Rollei from './Rollei'
import Sunglasses from './Sunglasses'
import Widget from '../components/widget'


// React-spring animates native elements, in this case <mesh/> etc,
// but it can also handle 3rd–party objs, just wrap them in "a".
const AnimatedMaterial = a(MeshDistortMaterial);




const Scene = forwardRef((setBg, ref) => {

    const sphere = useRef()
    const chaise = useRef()
    const sphere2 = useRef()
    const light = useRef()
    const [mode, setMode] = useState(false)
    const [down, setDown] = useState(false)
    const [hoveredRap, setHoveredRap] = useState(false)
    const [hoveredClassic, setHoveredClassic] = useState(false)


    useImperativeHandle(ref, () => {
        return {
            setBtnHovered: setBtnHovered,
            setBtnOut: setBtnOut
        };
    });

    const setBtnHovered = (btn) => {
        if (btn === 1) {
            setHoveredRap(true);
        } else {
            setHoveredClassic(true);
        }
    };

    const setBtnOut = (btn) => {
        if (btn === 1) {
            setHoveredRap(false);
        } else {
            setHoveredClassic(false);
        }
    };


    // Make the bubble float and follow the mouse
    // This is frame-based animation, useFrame subscribes the component to the render-loop
    useFrame((state) => {
        light.current.position.x = state.mouse.x * 20
        light.current.position.y = state.mouse.y * 20
    })


    // Springs for color and overall looks, this is state-driven animation
    // React-spring is physics based and turns static props into animated values
    const [{ wobble, coat, colorRap, colorClassic, ambient, env, spring }] = useSpring(
        {
            coat: mode && !hoveredRap ? 0.04 : 1,
            ambient: mode && !hoveredRap ? 1.5 : 0.1,
            env: mode && !hoveredRap ? 0.4 : 1,
            colorRap: hoveredRap ? 'red' : mode ? '#202020' : 'white',
            colorClassic: hoveredClassic ? 'blue' : 'white',
            config: (n) => n === 'wobble' && hoveredRap && { mass: 2, tension: 1000, friction: 10 }
        },
        [mode, hoveredRap, hoveredClassic, down]
    )





    return (
        <>
            <PerspectiveCamera position={[0, 0, 4]} fov={75}>
                <a.ambientLight intensity={ambient} />
                <a.pointLight ref={light} intensity={0.15} color="white" />
            </PerspectiveCamera>
            {/* <Suspense fallback={null}  ><Sunglasses hoveredRap={hoveredRap} positionCustom={[-2.5, -32.5, 0]} down={down}></Sunglasses></Suspense> */}
            {/* <Suspense fallback={null}  ><Nikon hoveredRap={hoveredClassic} positionCustom={[3, 0, -0.2]} down={down}></Nikon></Suspense> */}
            <Suspense fallback={"null"}  ><Rollei hoveredRap={hoveredClassic} positionCustom={[2.5, -0.8, 1]} down={down}></Rollei></Suspense>
            <Suspense fallback={"null"}  ><Canon hoveredRap={hoveredRap} positionCustom={[-2.5, -0.8, 1]} down={down}></Canon></Suspense>
            {/* <Suspense fallback={null} ><ArmchairGray hoveredRap={hoveredRap} positionCustom={[-3, -0.8, 0]} down={down}></ArmchairGray></Suspense> */}
        </>
    )
});

export default Scene;
