/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
author: MADE.COM (https://sketchfab.com/made-it)
license: CC-BY-NC-4.0 (http://creativecommons.org/licenses/by-nc/4.0/)
source: https://sketchfab.com/3d-models/ritchie-armchair-pearl-grey-3c06a4fd6d994f9abeec4d28f67a8ae6
title: Ritchie Armchair, Pearl Grey
convert gltf here: https://gltf.pmnd.rs/
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import { a } from '@react-spring/three'
import { useSpring } from '@react-spring/core'
import { useFrame } from 'react-three-fiber'


export default function Model(props) {
  const group = useRef()
  const { nodes, materials } = useGLTF('/sunglasses.gltf')


  const [{ wobbleLight, wobbleGltf }] = useSpring({
    wobbleLight: props.down ? 0.00 : props.hoveredRap ? 100 : 0,
    wobbleGltf: props.down ? 0.03 : props.hoveredRap ? 1.201 : 1.2,
    config: (n) => n === 'wobbleGltf' && props.hoveredRap && { mass: 2, tension: 1000, friction: 10 }
  },
    [props.hoveredRap]
  )

  useFrame(({ clock }) => {
    group.current.rotation.y = -clock.getElapsedTime() / 30;
  })

  return (
    <a.group position={props.positionCustom} scale={wobbleGltf.to((w) => [w, w, w])} ref={group} {...props} dispose={null}>
      <a.pointLight color="white" distance={2} position={[-2.5, -27.2, 1]} intensity={wobbleLight.to((w) => w)}></a.pointLight>
      <group rotation={[-Math.PI / 2, 0, -2]}>
        <group rotation={[Math.PI / 2, 0, 0]}>
          <group position={[0.06, 27.41, -0.65]}>
            <group ref={group} {...props} dispose={null}>
              <mesh
                geometry={nodes.Circle1.geometry}
                material={nodes.Circle1.material}
              />
            </group>
          </group>
        </group>

      </group>
    </a.group>
  )
}

useGLTF.preload('/sunglasses.gltf')
