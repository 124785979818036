/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
author: MADE.COM (https://sketchfab.com/made-it)
license: CC-BY-NC-4.0 (http://creativecommons.org/licenses/by-nc/4.0/)
source: https://sketchfab.com/3d-models/ritchie-armchair-pearl-grey-3c06a4fd6d994f9abeec4d28f67a8ae6
title: Ritchie Armchair, Pearl Grey
convert gltf here: https://gltf.pmnd.rs/
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import { a } from '@react-spring/three'
import { useSpring } from '@react-spring/core'
import { useFrame } from 'react-three-fiber'


export default function Model(props) {
  const group = useRef()
  const { nodes, materials } = useGLTF('/rollei.gltf')

  const [{ wobbleLight, wobbleGltf }] = useSpring(
    {
      wobbleLight: props.down ? 0.00 : props.hoveredRap ? 1 : 0,
      wobbleGltf: props.down ? 0.03 : props.hoveredRap ? 0.36 : 0.35,
      config: (n) => n === 'wobbleGltf' && props.hoveredRap && { mass: 2, tension: 1000, friction: 10 }
    },
    [props.hoveredRap]
  )


  useFrame(({ clock }) => {
    group.current.rotation.y = clock.getElapsedTime() / 40
  })

  return (

    <a.group position={props.positionCustom} scale={wobbleGltf.to((w) => [w, w, w])} ref={group} {...props} dispose={null}>
      <a.pointLight distance={2} position={[-100, -100, 2]} intensity={wobbleLight.to((w) => w)}></a.pointLight>
      <a.pointLight distance={2} position={[100, 100, 200]} intensity={wobbleLight.to((w) => w / 4)}></a.pointLight>
      <group rotation={[0, 90, 0]}>
        <group rotation={[0, 0, 0]}>
          <group position={[0, 0, 0]}>
            <group ref={group} {...props} dispose={null}>
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.polySurface53_lens_grp_rollei.geometry}
                material={nodes.polySurface53_lens_grp_rollei.material}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.lens_grp_rollei_polySurface47.geometry}
                material={nodes.lens_grp_rollei_polySurface47.material}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.lens_grp_rollei_polySurface52.geometry}
                material={nodes.lens_grp_rollei_polySurface52.material}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.rollei_polySurface250_body_grp.geometry}
                material={nodes.rollei_polySurface250_body_grp.material}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.rollei_body_grp_polySurface240.geometry}
                material={nodes.rollei_body_grp_polySurface240.material}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.rollei_polySurface185_bottom_grp.geometry}
                material={nodes.rollei_polySurface185_bottom_grp.material}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.rollei_bottom_grp_pCylinder30.geometry}
                material={nodes.rollei_bottom_grp_pCylinder30.material}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.rollei_body_grp_polySurface260.geometry}
                material={nodes.rollei_body_grp_polySurface260.material}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.rollei_body_grp_polySurface251_polySurface108.geometry}
                material={nodes.rollei_body_grp_polySurface251_polySurface108.material}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.rollei_body_grp_polySurface223.geometry}
                material={nodes.rollei_body_grp_polySurface223.material}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.rollei_body_grp_body.geometry}
                material={nodes.rollei_body_grp_body.material}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.rollei_body_grp_polySurface93.geometry}
                material={nodes.rollei_body_grp_polySurface93.material}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.rollei_body_grp_polySurface204.geometry}
                material={nodes.rollei_body_grp_polySurface204.material}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.rollei_body_grp_polySurface173.geometry}
                material={nodes.rollei_body_grp_polySurface173.material}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.rollei_body_grp_polySurface174.geometry}
                material={nodes.rollei_body_grp_polySurface174.material}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.rollei_body_grp_polySurface172.geometry}
                material={nodes.rollei_body_grp_polySurface172.material}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.rollei_body_grp_polySurface182.geometry}
                material={nodes.rollei_body_grp_polySurface182.material}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.rollei_body_grp_polySurface105.geometry}
                material={nodes.rollei_body_grp_polySurface105.material}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.rollei_body_grp_polySurface175.geometry}
                material={nodes.rollei_body_grp_polySurface175.material}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.rollei_body_grp_pSphere9.geometry}
                material={nodes.rollei_body_grp_pSphere9.material}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.rollei_body_grp_polySurface261_pSphere8.geometry}
                material={nodes.rollei_body_grp_polySurface261_pSphere8.material}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.rollei_body_grp_pSphere8_polySurface257.geometry}
                material={nodes.rollei_body_grp_pSphere8_polySurface257.material}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.rollei_body_grp_pSphere8_polySurface256.geometry}
                material={nodes.rollei_body_grp_pSphere8_polySurface256.material}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.rollei_body_grp_pSphere8_polySurface255.geometry}
                material={nodes.rollei_body_grp_pSphere8_polySurface255.material}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.rollei_body_grp_pSphere8_polySurface259.geometry}
                material={nodes.rollei_body_grp_pSphere8_polySurface259.material}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.rollei_body_grp_pSphere8_polySurface258.geometry}
                material={nodes.rollei_body_grp_pSphere8_polySurface258.material}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.rollei_body_grp_pSphere8_polySurface254.geometry}
                material={nodes.rollei_body_grp_pSphere8_polySurface254.material}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.rollei_body_grp_pSphere8_polySurface253.geometry}
                material={nodes.rollei_body_grp_pSphere8_polySurface253.material}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.rollei_body_grp_polySurface238_polySurface63.geometry}
                material={nodes.rollei_body_grp_polySurface238_polySurface63.material}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.rollei_body_grp_polySurface63_polySurface237.geometry}
                material={nodes.rollei_body_grp_polySurface63_polySurface237.material}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.rollei_body_grp_pSphere8_polySurface252.geometry}
                material={nodes.rollei_body_grp_pSphere8_polySurface252.material}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.rollei_body_grp_polySurface180.geometry}
                material={nodes.rollei_body_grp_polySurface180.material}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.rollei_body_grp_pCylinder19.geometry}
                material={nodes.rollei_body_grp_pCylinder19.material}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.rollei_body_grp_polySurface63_polySurface236.geometry}
                material={nodes.rollei_body_grp_polySurface63_polySurface236.material}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.rollei_body_grp_polySurface63_polySurface235.geometry}
                material={nodes.rollei_body_grp_polySurface63_polySurface235.material}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.rollei_body_grp_polySurface63_polySurface234.geometry}
                material={nodes.rollei_body_grp_polySurface63_polySurface234.material}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.rollei_body_grp_polySurface178.geometry}
                material={nodes.rollei_body_grp_polySurface178.material}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.rollei_body_grp_polySurface212.geometry}
                material={nodes.rollei_body_grp_polySurface212.material}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.rollei_body_grp_polySurface179.geometry}
                material={nodes.rollei_body_grp_polySurface179.material}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.rollei_body_grp_polySurface176.geometry}
                material={nodes.rollei_body_grp_polySurface176.material}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.rollei_body_grp_polySurface177.geometry}
                material={nodes.rollei_body_grp_polySurface177.material}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.rollei_polySurface284_upper_grp.geometry}
                material={nodes.rollei_polySurface284_upper_grp.material}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.lens_grp_rollei_polySurface228.geometry}
                material={nodes.lens_grp_rollei_polySurface228.material}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.lens_grp_rollei_polySurface231_polySurface157.geometry}
                material={nodes.lens_grp_rollei_polySurface231_polySurface157.material}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.rollei_upper_grp_pCube10.geometry}
                material={nodes.rollei_upper_grp_pCube10.material}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.rollei_upper_grp_polySurface285.geometry}
                material={nodes.rollei_upper_grp_polySurface285.material}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.lens_grp_rollei_polySurface159.geometry}
                material={nodes.lens_grp_rollei_polySurface159.material}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.lens_grp_rollei_polySurface222.geometry}
                material={nodes.lens_grp_rollei_polySurface222.material}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.lens_grp_rollei_polySurface157_polySurface233_polySurface230.geometry}
                material={nodes.lens_grp_rollei_polySurface157_polySurface233_polySurface230.material}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.lens_grp_rollei_polySurface157_polySurface230_polySurface232.geometry}
                material={nodes.lens_grp_rollei_polySurface157_polySurface230_polySurface232.material}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.lens_grp_rollei_pSphere3.geometry}
                material={nodes.lens_grp_rollei_pSphere3.material}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.lens_grp_rollei_pSphere1.geometry}
                material={nodes.lens_grp_rollei_pSphere1.material}
              />
            </group>
          </group>
        </group>
        {/* <mesh material={materials.FLOOR} geometry={nodes.FLOOR.geometry} /> */}
      </group>
    </a.group>
  )
}

useGLTF.preload('/rollei.gltf')
