/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
author: MADE.COM (https://sketchfab.com/made-it)
license: CC-BY-NC-4.0 (http://creativecommons.org/licenses/by-nc/4.0/)
source: https://sketchfab.com/3d-models/ritchie-armchair-pearl-grey-3c06a4fd6d994f9abeec4d28f67a8ae6
title: Ritchie Armchair, Pearl Grey
convert gltf here: https://gltf.pmnd.rs/
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import { a } from '@react-spring/three'
import { useSpring } from '@react-spring/core'
import { useFrame } from 'react-three-fiber'


export default function Model(props) {
  const group = useRef()
  const { nodes, materials } = useGLTF('/nikon.gltf')

  const [{ wobbleLight, wobbleGltf }] = useSpring(
    {
      wobbleLight: props.down ? 0.00 : props.hoveredRap ? 1 : 0,
      wobbleGltf: props.down ? 0.03 : props.hoveredRap ? 0.0083 : 0.008,
      config: (n) => n === 'wobbleGltf' && props.hoveredRap && { mass: 2, tension: 1000, friction: 10 }
    },
    [props.hoveredRap]
  )


  useFrame(({ clock }) => {
    group.current.rotation.y = clock.getElapsedTime() / 40
  })

  return (

    <a.group position={props.positionCustom} scale={wobbleGltf.to((w) => [w, w, w])} ref={group} {...props} dispose={null}>
      <a.pointLight distance={2} position={[-100, -100, 2]} intensity={wobbleLight.to((w) => w)}></a.pointLight>
      <a.pointLight distance={2} position={[100, 100, 200]} intensity={wobbleLight.to((w) => w / 4)}></a.pointLight>
      <group rotation={[-Math.PI / 2, 0, -0.4]}>
        <group rotation={[Math.PI / 2, 0, 0]}>
          <group position={[0.06, 27.41, -0.65]}>
            <group ref={group} {...props} dispose={null}>
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.PolySphere.geometry}
                material={nodes.PolySphere.material}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.PolySphere1.geometry}
                material={nodes.PolySphere1.material}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.PolySphere2.geometry}
                material={nodes.PolySphere2.material}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.PolySphere3.geometry}
                material={nodes.PolySphere3.material}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.PolySphere4_1.geometry}
                material={nodes.PolySphere4_1.material}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.UMesh_PM3D_Cylinder3D1_9.geometry}
                material={nodes.UMesh_PM3D_Cylinder3D1_9.material}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.PM3D_Cylinder3D1_8.geometry}
                material={nodes.PM3D_Cylinder3D1_8.material}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.PM3D_Cylinder3D1_5.geometry}
                material={nodes.PM3D_Cylinder3D1_5.material}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.PM3D_Cube3D2.geometry}
                material={nodes.PM3D_Cube3D2.material}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.PM3D_Cube3D3.geometry}
                material={nodes.PM3D_Cube3D3.material}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.PM3D_Cube3D4.geometry}
                material={nodes.PM3D_Cube3D4.material}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.PM3D_Cube3D1_1.geometry}
                material={nodes.PM3D_Cube3D1_1.material}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Recovered_Tool.geometry}
                material={nodes.Recovered_Tool.material}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.PM3D_Cube3D1_2.geometry}
                material={nodes.PM3D_Cube3D1_2.material}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.PM3D_Cube3D1_4.geometry}
                material={nodes.PM3D_Cube3D1_4.material}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.PM3D_Cylinder3D1.geometry}
                material={nodes.PM3D_Cylinder3D1.material}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.PM3D_Cube3D1_6.geometry}
                material={nodes.PM3D_Cube3D1_6.material}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.PM3D_Cylinder3D2_6.geometry}
                material={nodes.PM3D_Cylinder3D2_6.material}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.PM3D_Cylinder3D2_5.geometry}
                material={nodes.PM3D_Cylinder3D2_5.material}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.PM3D_Cylinder3D2_7.geometry}
                material={nodes.PM3D_Cylinder3D2_7.material}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.PM3D_Cube3D1_7.geometry}
                material={nodes.PM3D_Cube3D1_7.material}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.PM3D_Cylinder3D2_4.geometry}
                material={nodes.PM3D_Cylinder3D2_4.material}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.PM3D_Cylinder3D2_1.geometry}
                material={nodes.PM3D_Cylinder3D2_1.material}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.PM3D_Cylinder3D2.geometry}
                material={nodes.PM3D_Cylinder3D2.material}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.PM3D_Cylinder3D3_2.geometry}
                material={nodes.PM3D_Cylinder3D3_2.material}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.PM3D_Cylinder3D3_3.geometry}
                material={nodes.PM3D_Cylinder3D3_3.material}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.PM3D_Cylinder3D3_1.geometry}
                material={nodes.PM3D_Cylinder3D3_1.material}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.PM3D_Cylinder3D3.geometry}
                material={nodes.PM3D_Cylinder3D3.material}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.PM3D_Cylinder3D4.geometry}
                material={nodes.PM3D_Cylinder3D4.material}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.PM3D_Cube3D1_5.geometry}
                material={nodes.PM3D_Cube3D1_5.material}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.PM3D_Cylinder3D1_1.geometry}
                material={nodes.PM3D_Cylinder3D1_1.material}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.PM3D_Cube3D1.geometry}
                material={nodes.PM3D_Cube3D1.material}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.PM3D_Cylinder3D1_3.geometry}
                material={nodes.PM3D_Cylinder3D1_3.material}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.PM3D_Cylinder3D1_2.geometry}
                material={nodes.PM3D_Cylinder3D1_2.material}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.PM3D_Cylinder3D2_3.geometry}
                material={nodes.PM3D_Cylinder3D2_3.material}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.PM3D_Cylinder3D1_4.geometry}
                material={nodes.PM3D_Cylinder3D1_4.material}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.PM3D_Cube3D1_3.geometry}
                material={nodes.PM3D_Cube3D1_3.material}
              />
            </group>
          </group>
        </group>
        {/* <mesh material={materials.FLOOR} geometry={nodes.FLOOR.geometry} /> */}
      </group>
    </a.group>
  )
}

useGLTF.preload('/nikon.gltf')
