import ReactDOM from "react-dom"
import React, { Suspense, useRef, useEffect } from "react"
import { motion, useMotionValue, useTransform } from "framer-motion";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import data from "../assets/data";
import { NavLink } from "react-router-dom";
import SplitText from '@moxy/react-split-text';
import '@moxy/react-split-text/dist/index.css';
import Button from "../components/Button";
import Masonry from 'react-masonry-css'
import ImageLoader from "../components/ImageLoader";
import ImageLoader2 from "../components/ImageLoader2";




gsap.registerPlugin(ScrollTrigger);


const transition = { duration: 1.2, ease: [.43, .13, .23, .96] }

function importAll(r) {
    return r.keys().map(r);
}

export default function Projet({ match }) {

    const { params: { galleryId } } = match;
    const tl = gsap.timeline();
    const tl2 = gsap.timeline();


    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])


    useEffect(() => {
        tl2.from(".line", 4, {
            y: 100,
            ease: 'power4.out',
            skewY: 9,
            stagger: { // wrap advanced options in an object
                amount: 0.5,
                from: "top",
            }
        });
        tl.to(".word", 3.5, {
            opacity: 1,
            ease: 'expo.inOut',
            stagger: { // wrap advanced options in an object
                each: 0.2,
                from: "left",
                grid: "auto",
                ease: "power2.inOut",
            }
        });

        // tl.to(".photo", 3.5, {
        //     objectFit: "contain",
        //     ease: "expo.inOut",
        // });

    }, [])



    const breakpointColumnsObj = {
        default: 3,
        1100: 3,
        700: 1,
    };

    //SCROLL SKEWW
    let section = null;

    let currentPos = window.pageYOffset;

    const update = () => {

        const newPos = window.pageYOffset;
        const diff = newPos - currentPos;
        let speed = diff * 0.35;

        if (speed < -3) {
            speed = -3;
        } else if (speed > 3) {
            speed = 3;
        }

        section.style.transform = `skewY(${speed}deg)`;
        currentPos = newPos;
        requestAnimationFrame(update);
    }

    useEffect(() => {
        section = document.querySelector('.projet');
        update();
    }, [section]);


    return (
        data.images.map((index) => {
            const images = importAll(require.context(`../assets/images/`, true, /\.(jpe?g)$/));

            if (match.params.galleryId == index.id) {

                let nextItem = index.id + 1;
                nextItem > data.images.length - 1 ? (nextItem = "/gallery/0") : nextItem = "/gallery/" + nextItem;

                let prevItem = index.id - 1;
                let lastItem = data.images.length - 1;
                prevItem < 0 ? (prevItem = "/gallery/" + lastItem) : prevItem = "/gallery/" + prevItem;

                return (
                    <>
                        <motion.div exit={{ opacity: 0, top: -30, transition: { delay: 0.3, ...transition } }} ref={section} className="projet skewElem" key={index.id}>
                            <motion.div className="photo-cover" >
                                <div className="photo-container">
                                    <motion.img className="photo" src={require(`../assets${index.img}/image1.jpg`)} alt={index.title}></motion.img>
                                    <div className="titre">
                                        <motion.h2>{index.title}</motion.h2>
                                    </div>
                                </div>
                            </motion.div>
                            <div className="presentation">
                                <div className="texte-pres" >
                                    <div className="line word" dangerouslySetInnerHTML={{ __html: index.description }} />
                                </div>
                            </div>
                            <Masonry
                                breakpointCols={breakpointColumnsObj}
                                className="my-masonry-grid"
                                columnClassName="my-masonry-grid_column"
                            >
                                {images.map(function (object, i) {
                                    if (object.split('/')[3].split('.')[0] != "image1") {
                                        try {
                                            let url = require("../assets" + index.img + "/" + object.split('/')[3].split('.')[0] + ".jpg");
                                            return <ImageLoader2 className="photo" srcImage={(url)} ></ImageLoader2>;
                                        } catch (error) {
                                        }
                                    }
                                })}
                                <ImageLoader2 className="photo" srcImage={(require("../assets" + index.img + "/" + "image1.jpg"))} ></ImageLoader2>
                            </Masonry>

                            <div className="footer-pres" >
                                <Button link={prevItem}>Précédent</Button>
                                <Button link={nextItem}>Suivant</Button>
                            </div>
                        </motion.div>
                    </>
                );
            }

        })
    )
}
