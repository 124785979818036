import React, { useRef, useEffect, useState } from 'react'
import { Canvas } from 'react-three-fiber'
import { OrbitControls, Html } from '@react-three/drei'
import { useSpring } from '@react-spring/core'
import { a } from '@react-spring/web'
import { NavLink } from "react-router-dom";
import Scene from '../components/scene'
import Button from "../components/Button";



//REDUX
import { setMusicState } from '../actions'
import { setMusic } from '../actions'
import { useDispatch } from 'react-redux'

//MOTION
import { motion } from 'framer-motion';
const transition = { duration: .6, ease: [.43, .13, .23, .96] }



const Mood = () => {

    const dispatch = useDispatch();
    const ref = useRef(null);

    const [selected, setselected] = useState(false);




    const setBtnHovered = (btn) => {
        ref.current.setBtnHovered(btn);
        dispatch(setMusic(btn));
        console.log("HOVER", btn);
        dispatch(setMusicState(true));

    };


    const setBtnOut = (btn) => {
        ref.current.setBtnOut(btn);
        console.log("OUT", btn);
        if (!selected)
            dispatch(setMusicState(false));
    };


    const [{ background, fill }, set] = useSpring({ background: '$black', fill: '#202020' }, [])
    return (
        <>
            <motion.main initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { delay: 0.4, ...transition } }} exit={{ opacity: 0, transition: { delay: 1.2, ...transition } }} style={{ background }}>
                <Canvas className="canvas1" pixelRatio={[1, 2]}>
                    <Scene setBg={set} ref={ref} />
                    <OrbitControls screenSpacePanning={false} />
                </Canvas>
                <div className="card-question-2">
                    <motion.p initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { delay: 0.8, ...transition } }} exit={{ opacity: 0, height: 0, transition: { delay: 0.8, ...transition } }}>La musique m’inspire et m’accompagne toujours dans mon travail. Je vous propose donc de découvrir mes productions comme si vous étiez avec moi dans mes ambiances de création.</motion.p>
                    <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { delay: 1.2, ...transition } }} exit={{ opacity: 0, height: 0, transition: { delay: 0.4, ...transition } }} className="btn-list">

                        <div onClick={() => setselected(true)} onMouseEnter={() => setBtnHovered(1)} onMouseOut={() => setBtnOut(1)} ><Button link="/home">Street</Button></div>
                        <div onClick={() => setselected(true)} onMouseEnter={() => setBtnHovered(2)} onMouseOut={() => setBtnOut(2)} ><Button link="/home">Classique</Button></div>

                    </motion.div>
                </div>
            </motion.main>
        </>
    )
}

export default Mood
