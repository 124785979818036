import React, { useRef, useEffect } from 'react'
import Article from '../components/Article'
import HoverVideoPlayer from 'react-hover-video-player';
import Video from '../assets/video.mp4'
import Yourspace from '../assets/yourspace-min.png'
import { NavLink } from "react-router-dom";
import Data from '../assets/data'
import gsap from "gsap";
import Button from "../components/Button";


//TEXT MOTION
import SplitText from '@moxy/react-split-text';
import '@moxy/react-split-text/dist/index.css';

//MOTION
import { motion } from 'framer-motion';
const transition = { duration: 1.2, ease: [.43, .13, .23, .96] }





const Home = () => {
    const tl = gsap.timeline();
    const tl2 = gsap.timeline();
    const ref = useRef();

    useEffect(() => {


        tl2.from(".line", 4, {
            y: 100,
            ease: 'power4.out',
            skewY: 9,
            stagger: { // wrap advanced options in an object
                amount: 0.5,
                from: "top",
            }
        });
        tl.to(".word", 3.5, {
            opacity: 1,
            ease: 'expo.inOut',
            stagger: { // wrap advanced options in an object
                each: 0.2,
                from: "left",
                grid: "auto",
                ease: "power2.inOut",
            }
        });
    }, [])





    return (
        <>
            <div className="page">
                <div className="container">
                    <div className="container-home">
                        <motion.div className="texte-pres" exit={{ opacity: 0, transition: { delay: 1.2, ...transition } }}>
                            {/* <div className="line"><SplitText className="word">"La photographie est un art me</SplitText></div>
                            <div className="line"><SplitText className="word">permettant de transmettre</SplitText></div>
                            <div className="line"><SplitText className="word">mes émotions et celles de ceux</SplitText></div>
                            <div className="line"><SplitText className="word">qui m’entourent."</SplitText></div> */}
                            <div className="line"><SplitText className="word">La photographie ça m’occupe. Il y a quelques années, j’ai eu soudainement l’appétit de prendre des photos, tout comme d’autres lubies sont entrées dans ta vie et en sont ressorties. La photo c’est resté. Alors je ne sais pas comment appeler ça mais depuis maintenant quelques années, je me balade avec une caméra. C’est ça que je fais.</SplitText></div>
                        </motion.div>
                        <div className="photo-pres">

                            <motion.div className="motion-btn" initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { delay: 1.6, ...transition } }} exit={{ opacity: 0, transition: { delay: 0.1, ...transition } }}>
                                <Button link="/gallery">Voir les photos</Button>
                            </motion.div>


                            <motion.div className="motion" initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { delay: 1.2, ...transition } }} exit={{ opacity: 0, transition: { delay: 1.2, ...transition } }}>
                                <HoverVideoPlayer
                                    className="player-wrapper"
                                    disablePictureInPicture={false}
                                    focused={true}
                                    videoSrc={Video}
                                    loop={true}
                                />
                            </motion.div>
                        </div>
                        <motion.footer className="motion" initial={{ opacity: 0, width: 0 }} animate={{ opacity: 1, width: '100%', transition: { delay: 1.2, ...transition } }} exit={{ opacity: 0, transition: { delay: 1.2, ...transition } }} className="footer">
                            <p className="marquee">
                                <span>
                                    {Data.images.map((index) => {
                                        return (
                                            <NavLink key={index.id + 10} to={'/gallery/' + index.id} exact> {index.title} - </NavLink>
                                        )
                                    })}
                                </span>
                            </p>
                            <p className="marquee marquee2">
                                <span>
                                    {Data.images.map((index) => {
                                        return (
                                            <NavLink key={index.id} to={'/gallery/' + index.id} exact> {index.title} - </NavLink>
                                        )
                                    })}
                                </span>
                            </p>

                        </motion.footer>

                    </div>
                </div >
            </div >
        </>
    )
}

export default Home
