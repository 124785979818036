const initialState = {
    theme: 'light'
};


const themeReducer = (state = initialState, action) => {
    console.log("store payload: ",action.payload);
    switch (action.type) {
        case 'SET_THEME':
            return { ...state, theme: action.payload.state  };
        default:
            return state
    }
}

export default themeReducer;