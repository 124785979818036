import React from 'react';
import FadeIn from "react-lazyload-fadein";



const ImageLoader2 = (srcImage) => {
    return (
        <FadeIn height={600} duration={500} easing={"ease-in-out"}>
            {onload => (
                <img
                    src={srcImage.srcImage}
                    onLoad={onload}
                // style={{ height: 600 }}
                />
            )}
        </FadeIn>
    );
}

export default ImageLoader2;