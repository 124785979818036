import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { ReactComponent as UpArrow } from "../assets/arrow-top.svg";
import { openMenu, closeMenu } from "../animations/menuAnimations";
import Widget from "./widget";
import { useSelector } from 'react-redux';
// Define reducer

//MOTION
import { motion } from 'framer-motion';
const transition = { duration: .6, ease: [.43, .13, .23, .96] }


const Header = ({ history, dimensions }) => {
    const currentTheme = useSelector(state => state.theme.theme);
    const [menuState, setMenuState] = useState({ menuOpened: false });
    useEffect(() => {
        //Listening for page changes.
        history.listen(() => {
            setMenuState({ menuOpened: false });
        });
        if (menuState.menuOpened === true) {
            openMenu(dimensions.width,currentTheme);
        } else if (menuState.menuOpened === false) {
            closeMenu(currentTheme);
        }
    });


    return (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { delay: 1.4, ...transition } }} className='header'>
            <div className='container'>
                <div className='row v-center space-between'>
                    <div className='nav-toggle'>
                        <div
                            onClick={() => setMenuState({ menuOpened: true })}
                            className='hamburger-menu'>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                        <div
                            className='hamburger-menu-close'
                            onClick={() => setMenuState({ menuOpened: false })}>
                            <span></span>
                            <span></span>
                        </div>
                    </div>
                    <div className='logo'>
                        <NavLink to='/home' exact>
                            <span>Mazlum</span><span> Küsne</span>
                        </NavLink>
                    </div>
                    <div className='widget'>
                        <Widget />
                    </div>
                </div>
            </div>
        </motion.div>
    );
};
export default withRouter(Header);