/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
author: MADE.COM (https://sketchfab.com/made-it)
license: CC-BY-NC-4.0 (http://creativecommons.org/licenses/by-nc/4.0/)
source: https://sketchfab.com/3d-models/ritchie-armchair-pearl-grey-3c06a4fd6d994f9abeec4d28f67a8ae6
title: Ritchie Armchair, Pearl Grey
*/

import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import { a } from '@react-spring/three'
import { useSpring } from '@react-spring/core'


export default function Model(props) {
  const group = useRef()
  const { nodes, materials } = useGLTF('/armchairGray.gltf')

  const [{ wobbleLight, wobbleGltf }] = useSpring(
    {
      wobbleLight: props.down ? 0.00 : props.hoveredRap ? 4 : 0,
      wobbleGltf: props.down ? 0.03 : props.hoveredRap ? 0.031 : 0.03,
      config: (n) => n === 'wobbleGltf' && props.hoveredRap && { mass: 2, tension: 1000, friction: 10 }
    },
    [props.hoveredRap]
  )


  return (
    <a.group position={props.positionCustom} scale={wobbleGltf.to((w) => [w, w, w])} ref={group} {...props} dispose={null}>
      <a.pointLight distance={2} position={[-1, 3, 0]} intensity={wobbleLight.to((w) => w)}></a.pointLight>
      <group rotation={[-Math.PI / 2, 0, 0]}>
        <group rotation={[Math.PI / 2, 0, 0]}>
          <group position={[0.06, 27.41, -0.65]}>
            <mesh material={materials.PEARL_GREY} geometry={nodes.PEARL_GREY.geometry} />
            <mesh material={materials.EnvironmentAmbientLight} geometry={nodes.RAINBOW_BUTTONS.geometry} />
            <mesh material={materials.LEGS} geometry={nodes.LEGS.geometry} />
          </group>
        </group>
        {/* <mesh material={materials.FLOOR} geometry={nodes.FLOOR.geometry} /> */}
      </group>
    </a.group>
  )
}

useGLTF.preload('/armchairGray.gltf')
