import React from 'react'
import Question from '../components/question'

const Intro = () => {

    return (
        <>
            <div className="page">
                <div className="container">
                    <Question />
                </div>
            </div>
        </>
    )
}

export default Intro
