import React, { useEffect, useRef, useState, forwardRef, useImperativeHandle } from "react";
import useSound from 'use-sound';
import { useSelector } from 'react-redux';

import musicRap from '../assets/rap.mp3';
import musicClassic from '../assets/classic.mp3';

const Player = () => {

    const musicState = useSelector(state => state.player.musicState);
    const music = useSelector(state => state.player.music);
    const store = useSelector(state => state.player);


    const [play, { stop }] = useSound(
        musicRap,
        { volume: 0.20, interrupt: true }
    );

    const [playClassic, { pause }] = useSound(
        musicClassic,
        { volume: 0.20, interrupt: true }
    );


    useEffect(() => {
        if (musicState) {
            console.log("STATE: ", musicState);
            if (music == musicRap) {
                play()
                pause()
            } else {
                playClassic()
                stop()
            }
        } else {
            if (music == musicRap) {
                stop()
            } else {
                pause()
            }
        }
    }, [store])




    return (
        <>
        </>
    );
};

export default Player;