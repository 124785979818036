/*const Data = [
    { id: 0, subtitle: 'lumière rouge', title: "lumière rouge", img: "image3" },
    { id: 1, subtitle: 'lumin', title: "voiture bleue", img: "image5" },
    { id: 2, subtitle: 'lumin', title: "la face cachée de l'homme", img: "image6" },
    { id: 3, subtitle: 'lumière rouge', title: "lumière rouge", img: "image3" },
    { id: 4, subtitle: 'lumin', title: "voiture bleue", img: "image5" },
    { id: 5, subtitle: 'lumin', title: "la face cachée de l'homme", img: "image6" }
];



export default { Data };*/


import { createRef } from "react"
import { Vector3 } from "three"
const Data = {
    sections: 3,
    pages: 3,
    zoom: 75,
    images: [
        { id: 0, description: 'Parfois je retourne chez mes grand parents et l’ambiance de la ville, les habitants, le soleil, les rues, les parfums me donnent à chaque fois le même sentiment de nostalgie et de bonheur. ', title: "Pazarcık", img: "/images/turkey-light" },
        { id: 1, description: 'Le fantasme de mon enfance et le fantasme du photographe en moi. Les gens, les bâtiments et les paysages y sont beaux. Il y a du charisme qui se dégage de ce pays.', title: "Japan", img: "/images/Japon-light" },
        { id: 2, description: 'La rue, c’est partout la rue. C’est là où tout le monde passe et juste à l’observer, on y comprend des histoires. C’est ça qui me plait dans la photographie de rue. On peut passer cent fois dans la même rue, mais son histoire ne sera jamais la même.', title: "La rue", img: "/images/Larue-light" },
        { id: 3, description: 'Prendre une caméra pour aller faire du sport c’est ma méthode à moi pour en faire moins. Une technique d’escroc mais capturer le sport, c’est fascinant. La volonté de se dépasser et la sueur que ça implique crée des regards uniques.', title: "Equipe", img: "/images/Lesgarsfontduspor" },
        { id: 4, description: 'Dan on l’appelle Danactiv. Passionné dans son art, dans ses relations, dans son travail. De ses images à sa musique, il m’impressionne. Alors qu’il avait l’habitude d’être derrière la caméra, ce jour-là il m’a proposé d’être de l’autre côté de l’appareil.', title: "DAN", img: "/images/Orlando-light" },
        { id: 5, description: "«Et ils tournent et ils dansent<br>Comme des soleils crachés <br>Dans le son déchiré<br>D'un accordéon rance<br>Ils se tordent le cou<br>Pour mieux s'entendre rire<br>Jusqu'à ce que tout à coup<br>L'accordéon expire, alors »", title: "Amsterdam", img: "/images/Amsterdam-light" },
        { id: 6, description: '« Leica c’est bien de la merde » c’est ce que me dit le proprio de la voiture bleue. Je m’arrête cinq minutes devant une boutique pour prendre quelques clichés de cette Monarch et là j’entends les cloches de la porte du magasin raisonner. « Vous n’êtes pas le premier à photographier ma voiture vous savez ! Vous avez quoi dans les mains ? Moi j’ai acheté un Leica et je peux même pas changer le chiffre là sur l’objectif alors je peux vous dire : Pour le prix, Leica c’est bien de la merde » et il est retourné dans sa boutique. Un type aussi incroyable que sa voiture.', title: "Mercury", img: "/images/Blue-light" },
        { id: 7, description: "« Scoală gazdă din pătuţ <br>Florile dalbe, <br>Şi ne dă un colăcuţ<br>Florile, florile dalbe. <br>Că mămuca n-o făcut<br>Florile dalbe, <br>Sâtă deasa n-o avut<br>Florile, florile dalbe »", title: "Bucharest", img: "/images/Bucharest-light" },
        { id: 8, description: '24.arte c’est Jo. Jo c’est un artiste, mais avant tout c’est un ami. Un 25 décembre au matin, Jo me donne rendez-vous pour la première fois pour qu’on fasse de la photo. Ce jour-là avec Jo, on ne discute pas de direction artistique ou de mise en scène. On parle de musique et de cinéma, de notre rapport à la création, de Londres et de sa peinture. On en oublie qu’on prend des photos.', title: "24.arte", img: "/images/Jose-Light" },
        { id: 9, description: 'La musique a une grande importance dans ma vie et quand j’ai entendu Woodi sur scène pour la première fois ça a été un vrai coup de foudre. Je rêvais de les avoir à travers mon objectif puis un jour, ils m’ont appelé. Quel honneur d’avoir travaillé avec eux durant leur session studio.', title: "Woodi", img: "/images/Woodi-light" }
    ],
    top: createRef()
}

export default Data