import React, { useEffect, useRef } from "react";
import { NavLink } from "react-router-dom";
import gsap from "gsap";

const Navigation = () => {

    var ref = useRef();

    var venueImageWrap = null;
    var venueImage = null;

    useEffect(() => {
        const allVenues = gsap.utils.toArray(".img-item");
        venueImageWrap = document.querySelector(".nav-links-img-wrap");
        venueImage = document.querySelector(".nav-links-img");

        allVenues.forEach((link) => {
            link.addEventListener("mouseenter", venueHover);
            link.addEventListener("mouseleave", venueHover);
            link.addEventListener("mousemove", moveVenueImage);
        });
    }, [ref])

    function moveVenueImage(e) {
        let xpos = e.clientX;
        let ypos = e.clientY;

        const tl = gsap.timeline();
        tl.to(venueImageWrap, {
            x: xpos,
            y: ypos
        });
    }

    function venueHover(e) {
        if (e.type === "mouseenter") {
            const targetImage = e.target.dataset.img;
            const t1 = gsap.timeline();
            t1.set(venueImage, {
                backgroundImage: `url(${targetImage})`
            }).to(venueImageWrap, {
                duration: 0.5,
                autoAlpha: 1
            });
        } else if (e.type === "mouseleave") {
            const tl = gsap.timeline();
            tl.to(venueImageWrap, {
                duration: 0.5,
                autoAlpha: 0
            });
        }
    }

    return (
        <nav className="nav">
            <div className="nav-content" ref={ref}>
                <ul className='nav-links'>
                    <li>
                        <NavLink to='/home' exact className="img-item" data-img="/image6.jpg">
                            Accueil
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to='/gallery' exact className="img-item" data-img="/image3.jpg">
                            Photos
                        </NavLink>
                    </li>
                    <li>
                        <a href='mailto: mazlum@bonjouravous.ch' exact className="img-item" data-img="/image5.png">
                            Contact
                        </a>
                    </li>
                </ul>
                <div className="nav-links-img-wrap">
                    <div className="nav-links-img"></div>
                </div>
                <div className="nav-footer">
                    <div className="nav-copyright">Web design et développement par <a href='https://www.linkedin.com/in/anthony-cherbuin-220b96119/' target="_BLANK" >Anthony Cherbuin</a></div>
                    <ul className='nav-footer-social'>
                        <li>
                            <a href='https://www.instagram.com/kusne.mazlum' target="_BLANK" >
                                INSTAGRAM
                            </a>
                        </li>
                        <li>
                            <a href='https://www.facebook.com/Mazlum.Kusne' target="_BLANK" >
                                FACEBOOK
                            </a>
                        </li>
                        <li>
                            <a href='https://www.linkedin.com/in/mazlum-k%C3%BCsne-b94146146/' target="_BLANK" >
                                LINKEDIN
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
};

export default Navigation;