import gsap from "gsap";
import TweenMax from "gsap";


let tl = gsap.timeline();
let tl2 = gsap.timeline();
let tl3 = gsap.timeline();
let tl4 = gsap.timeline();


export const openMenu = (width, currentTheme) => {


    tl2.to(".header", 0, {
        // css: { background: "transparent" }
    });

    if(currentTheme == 'dark'){
        tl2.to(".logo>a>span", 2, {
            css: { color: "#E2E1DD" }
        })
        tl4.to(".header .nav-toggle .hamburger-menu span", 2, {
            css: { backgroundColor: "#E2E1DD" }
        }).to(".header .nav-toggle .hamburger-menu-close span", 2, {
            css: { background: "#E2E1DD" }
        })
    }else{
        tl2.to(".logo>a>span", 2, {
            css: { color: "#232323" }
        })
        tl4.to(".header .nav-toggle .hamburger-menu span", 2, {
            css: { backgroundColor: "#232323" }
        }).to(".header .nav-toggle .hamburger-menu-close span", 2, {
            css: { background: "#232323" }
        })
    }


    tl.to("nav", 0, {
        css: { display: "block" }

    }).to("body", 0, { css: { overflow: "hidden" } })
        .to(".App", 1, {
            x: window.innerWidth,
            ease: "expo.inOut"
        }).to(".hamburger-menu span", 0.6, {
            delay: -1.0,
            scaleX: 0,
            transformOrigin: "50% 0%",
            ease: "expo.inOut",

        }).to(".hamburger-menu", 0.6, {
            delay: -0.8,
            css: { width: 0 },
        })
        .to(".hamburger-menu-close", 0.6, {
            delay: -0.8,
            scaleX: -1,
            transformOrigin: "50% 0%",
            ease: "expo.inOut",
            css: { display: "block" }
        });
};

export const closeMenu = (currentTheme) => {

    if(currentTheme == 'dark'){
        tl2.to(".logo>a>span", 2, {
            css: { color: "#232323" }
        })
        tl4.to(".header .nav-toggle .hamburger-menu span", 2, {
            css: { backgroundColor: "#232323" }
        })
    }else{
        tl2.to(".logo>a>span", 2, {
            css: { color: "#E2E1DD" }
        })
        tl4.to(".header .nav-toggle .hamburger-menu span", 0, {
            css: { backgroundColor: "#E2E1DD" }
        })
    }



    tl.to(".App", 1, {
        x: 0,
        ease: "expo.inOut"
    }).to(".header", 0, {
        // css: { background: "linear-gradient(to bottom, #232323 0%, #232323 50%, transparent 100%)" }
    })
        .to(".hamburger-menu span", 0.6, {
            delay: -0.6,
            scaleX: 1,
            transformOrigin: "50% 0%",
            ease: "expo.inOut",
        })
        .to(".hamburger-menu", 0.6, {
            delay: -0.4,
            css: { width: 35 },
        })
        .to("body", 0, { css: { overflow: "auto" } })
        .to("nav", 0, {
            css: { display: "none" }
        });

    tl3.to(".hamburger-menu-close", 0.6, {
        delay: 0,
        css: { display: "none" }
    })
};