import * as React from "react";
import { useState, useEffect } from 'react'
import { motion, useMotionValue, useTransform } from "framer-motion";
import Data from '../assets/data'
import { useHistory } from 'react-router-dom'
import gsap from "gsap";

const width = 350;
const padding = 80;
const size = 350;

const transition = { duration: 1.2, ease: [.43, .13, .23, .96] }


export default function Gallery() {

    const history = useHistory();
    const scrollX = useMotionValue(0);
    const [xState, setXState] = useState(scrollX.get());
    const [temporaryScroll, settemporaryScroll] = useState(scrollX.get());
    useEffect(() => scrollX.onChange(setXState(scrollX)), [])
    const tl = gsap.timeline();

    var startTime, endTime;

    const [selected, setselected] = useState(null)

    function start() {
        startTime = new Date();
    };

    function end(id) {
        endTime = new Date();
        var timeDiff = endTime - startTime; //in ms

        //This is a click and not a drag
        if (timeDiff < 150) {
            setselected(id);

            history.push('/gallery/' + id);

            // Calcul pour centrer l'image sur l'écran avant transition !attention 350 = width des images
            let pxToMove = 0;
            pxToMove = (((window.innerWidth / 2) - ((350) / 2) - padding / 2) - (id * 350) - (id * padding));
            var toResize = document.getElementById(id);
            tl.to(".scrollable", 1.4,
                {
                    x: pxToMove, y: 0, z: 0,
                    //faire avec bezier
                    ease: 'expo.inOut',
                }).to(".photo", 0.8, {
                    rotate: 0,
                });

        }


    }



    function setscroll(e) {
        if (e.deltaY < 0) {
            //console.log(temp);
            //settemporaryScroll({ ...xState });
            //console.log("temp: ", temporaryScroll, xState)
            //setXState(temporaryScroll)

            // settemporaryScroll({ ...xState, current: temp });

            //setXState({ ...xState });


        } else {
            //  setXState(xState + 0.01);
        }
    };





    const width = useTransform(
        scrollX,
        [0, -(getWidth(Data.images) - ((window.innerWidth / (size + padding)) * (size)))],
        ["calc(1% - 0px)", "calc(100% - 0px)"]
    );

    const left = useTransform(
        scrollX,
        [0, -getWidth(Data.images) + size],
        ["calc(-25px - 0px)", "calc(100% - 0px)"]
    );

    const rotate = useTransform(
        scrollX,
        [0, -getWidth(Data.images) + size],
        [-2, 2]
    );

    const left2 = useTransform(
        scrollX,
        [0, -getWidth(Data.images) + size],
        ["calc(-25px - 0px)", "calc(120% - 0px)"]
    );



    return (
        <div className="transition">
            <div className="rotate">
                <motion.div className="image-container" whileTap={{ cursor: "grabbing" }}>
                    <motion.div className="scrollable" onWheel={(e) => setscroll(e)}
                        drag="x"
                        style={{ x: xState }}
                        dragElastic={0.5}
                        dragTransition={{ bounceStiffness: 200, bounceDamping: 12 }}
                        dragConstraints={{
                            //left: -getWidth(Data.images) + size,
                            left: -(getWidth(Data.images) - ((window.innerWidth / (size + padding)) * (size))),
                            // right: window.innerWidth - size,
                            right: (window.innerWidth - ((window.innerWidth / (size)) * size))
                        }}

                    >
                        {Data.images.map((index) => {
                            return (
                                <>
                                    <motion.div initial={{ opacity: 0, top: 20 }} animate={{ opacity: 1, top: 0, transition: { delay: 1 + (index.id / 2.5), ...transition } }} id={index.id} className="photo-container" key={index.id} onMouseDown={() => start()} onMouseUp={() => end(index.id)}>
                                        {(selected === index.id) ? <motion.img className="photo" src={require(`../assets${index.img}/image1.jpg`)} alt={index.title}></motion.img> : <motion.img style={{ rotate: rotate }} exit={{ opacity: 0, transition: { delay: 0, ...transition } }} className="photo" src={require(`../assets${index.img}/image1.jpg`)} alt={index.title}></motion.img>}
                                        <div className="photo-texte">
                                            {(selected === index.id) ? <motion.h2 exit={{ left: 0, top: -30, transition: { delay: 2.3, ...transition } }} style={{ left: left }}>{index.title}</motion.h2> : <motion.h2 exit={{ opacity: 0, transition: { delay: 0, ...transition } }} style={{ left: left }}>{index.title}</motion.h2>}
                                            {(selected === index.id) ? <motion.h3 exit={{ opacity: 0, transition: { delay: 1.2, ...transition } }} style={{ left: left2 }}>Voir plus</motion.h3> : <motion.h3 exit={{ opacity: 0, transition: { delay: 0, ...transition } }} style={{ left: left2 }}>Voir plus</motion.h3>}
                                        </div>
                                    </motion.div>
                                </>
                            );
                        })}
                    </motion.div>
                </motion.div>
            </div>
            <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { delay: 2.4, ...transition } }} exit={{ opacity: 0, transition: { delay: 0, ...transition } }} className="progress-bar-container"><motion.div className="progress-bar" style={{ width: width }} /></motion.div>
        </div>
    );
}

function getWidth(data) {
    const totalWidth = (data.length) * (width);
    const totalPadding = (data.length) * padding;
    const totalScroll = totalWidth + totalPadding;
    return totalScroll;
}
