export const setMusic = (music) => {
    return {
        type: 'SET_MUSIC',
        payload: { music }
    }
};

export const setMusicState = (state) => ({
    type: 'SET_MUSIC_STATE',
    payload: { state }
});

export const setThemeState = (state) => ({
    type: 'SET_THEME',
    payload: { state }
});