import React, { useState, useEffect } from 'react';


const IntroOverlay = () => {


    const [counter, setCounter] = useState(0);

    useEffect(() => {
        const timeout = setTimeout(() => {
            if (counter < 100)
                setCounter(counter + 4);
        }, 96);
    }, [counter]);


    return (
        <div className="intro-overlay">
            <div className="top">
                <div className="overlay-top">
                    <div className="loading-percentage">{counter}%</div>
                </div>
            </div>
        </div>
    );
}

export default IntroOverlay;