/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
author: MADE.COM (https://sketchfab.com/made-it)
license: CC-BY-NC-4.0 (http://creativecommons.org/licenses/by-nc/4.0/)
source: https://sketchfab.com/3d-models/ritchie-armchair-pearl-grey-3c06a4fd6d994f9abeec4d28f67a8ae6
title: Ritchie Armchair, Pearl Grey
convert gltf here: https://gltf.pmnd.rs/
*/
import * as THREE from 'three'
import React, { useRef } from 'react'
import { useGLTF } from '@react-three/drei'
import { a } from '@react-spring/three'
import { useSpring } from '@react-spring/core'
import { useFrame, useLoader } from 'react-three-fiber'


export default function Model(props) {
  const group = useRef()
  const { nodes, materials } = useGLTF('/canon.gltf')


  const [{ wobbleLight, wobbleGltf }] = useSpring(
    {
      wobbleLight: props.down ? 0.00 : props.hoveredRap ? 1 : 0,
      wobbleGltf: props.down ? 0.03 : props.hoveredRap ? 15.3 : 15,
      config: (n) => n === 'wobbleGltf' && props.hoveredRap && { mass: 2, tension: 1000, friction: 10 }
    },
    [props.hoveredRap]
  )


  useFrame(({ clock }) => {
    group.current.rotation.y = clock.getElapsedTime() / 40
  })

  // const texture = useLoader(THREE.TextureLoader, "/CANON_AT1_METL_1k.png")
  // console.log("nodes: ", nodes['Canon_AT-1'].material);
  return (


    <a.group position={props.positionCustom} scale={wobbleGltf.to((w) => [w, w, w])} ref={group} {...props} dispose={null}>
      <a.pointLight distance={2} position={[-100, -100, 2]} intensity={wobbleLight.to((w) => w)}></a.pointLight>
      <a.pointLight distance={2} position={[100, 100, 200]} intensity={wobbleLight.to((w) => w / 4)}></a.pointLight>
      <group rotation={[0, 0, 0]}>
        <group rotation={[0, 0, 0]}>
          <group position={[0, 0, 0]}>
            <group ref={group} {...props} dispose={null}>

              {/* <meshStandardMaterial attach="material" map={texture} /> */}
              {/* <meshBasicMaterial attach="material" map={texture} /> */}
              <mesh
                castShadow
                receiveShadow
                geometry={nodes['Canon_AT-1'].geometry}
                material={nodes['Canon_AT-1'].material}
              ></mesh>
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Battery_Check.geometry}
                material={nodes.Battery_Check.material}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Spool.geometry}
                material={nodes.Spool.material}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Film_Advance.geometry}
                material={nodes.Film_Advance.material}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Shutter.geometry}
                material={nodes.Shutter.material}
              />
              <mesh
                castShadow
                receiveShadow
                geometry={nodes.Shutter_Speed.geometry}
                material={nodes.Shutter_Speed.material}
              />
            </group>
          </group>
        </group>
        {/* <mesh material={materials.FLOOR} geometry={nodes.FLOOR.geometry} /> */}
      </group>
    </a.group>
  )
}

useGLTF.preload('/canon.gltf')
