import { createGlobalStyle } from "styled-components"
export const GlobalStyles = createGlobalStyle`
  .page {
    background-color: ${({ theme }) => theme.background};
    transition: all 0.50s linear;
  }
  .page .container-home .texte-pres .react-split-text{
    color: ${({ theme }) => theme.text};
    transition: all 0.50s linear;
  }

  // .header .nav-toggle .hamburger-menu span{
  //   background: ${({ theme }) => theme.text};
  //   transition: all 0.50s linear;
  // }

  .btn .btnSticky{
    border: 1px solid ${({ theme }) => theme.text};
    // transition: all 0.50s linear;
  }

  .btn:hover .btnSticky{
    background-color: ${({ theme }) => theme.text};
    color: ${({ theme }) => theme.background};
    // transition: all 0.50s linear;
  }


  .btn{
    color: ${({ theme }) => theme.text};
    // transition: all 0.50s linear;
  }

  body, html{
    background-color: ${({ theme }) => theme.background};
  }

  .ball{
    background-color: ${({ theme }) => theme.text} !important;
  }

  .nav .nav-content .nav-links li a{
    color: ${({ theme }) => theme.background};
  }

  .nav .nav-content .nav-footer .nav-footer-social li a{
    color: ${({ theme }) => theme.background};
  }

  .nav .nav-content .nav-footer .nav-copyright a{
    color: ${({ theme }) => theme.background};
  }

  .nav .nav-content .nav-footer .nav-copyright{
    color: ${({ theme }) => theme.background};
  }

  .nav .nav-content .nav-footer .nav-copyright:hover > a{
    text-decoration: underline 0.15em ${({ theme }) => theme.background};
  }

  .page .container-home .footer .marquee{
    border-top: 2px solid ${({ theme }) => theme.text};
    // transition: all 0.50s linear;
  }

  .page .container-home .footer .marquee a{
    color: ${({ theme }) => theme.text};
    // transition: all 0.50s linear;
  }

  .page .container-home .footer:before{
    background: linear-gradient(to right, ${({ theme }) => theme.background} 0%, transparent 100%);
    // transition: all 0s linear;
  }

  .page .container-home .footer:after{
    background: linear-gradient(to left, ${({ theme }) => theme.background} 0%, transparent 100%);
    // transition: all 0s linear;
  }

  .widget .border{
    border: 2px solid ${({ theme }) => theme.text};
    transition: all 0s linear;
  }

  .widget .border :nth-child(1){
    color: ${({ theme }) => theme.background};
    // transition: all 0.50s linear;
  }

  .border .screenMode{
    background: ${({ theme }) => theme.text};
    border: 1px solid ${({ theme }) => theme.text};
    transition: all 0.50s linear;
  }

  .widget .border .player span{
    background: ${({ theme }) => theme.text};
    transition: all 0.50s linear;
  }

  .nav{
    background: ${({ theme }) => theme.text};
    transition: all 0.50s linear;
  }

  .projet{
    background: ${({ theme }) => theme.background};
    transition: all 0.50s linear;
  }

  .projet .presentation .texte-pres .word{
    color: ${({ theme }) => theme.text};
    transition: all 0.50s linear;
  }

  .transition{
    background: ${({ theme }) => theme.background};
    transition: all 0.50s linear;
  }

  .transition .rotate .image-container .scrollable .photo-container .photo-texte h2{
    color: ${({ theme }) => theme.text};
    // transition: all 0.50s linear;
    mix-blend-mode: ${({ theme }) => theme.overlay};
  }

  .transition .rotate .image-container .scrollable .photo-container .photo-texte h3{
    color: ${({ theme }) => theme.text};
    // transition: all 0.50s linear;
    mix-blend-mode: ${({ theme }) => theme.overlay};
  }

  .transition .progress-bar-container .progress-bar{
    background: ${({ theme }) => theme.text};
    transition: all 0.50s linear;
  }

  .transition .progress-bar-container ::before{
    background: ${({ theme }) => theme.text};
    transition: all 0.50s linear;
  }

  .projet .photo-cover .photo-container .titre h2{
    color: ${({ theme }) => theme.text};
    mix-blend-mode: ${({ theme }) => theme.overlay};
  }
  `