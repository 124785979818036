import React, { useEffect, useState } from "react";
import "./styles/App.scss";
import gsap from "gsap";
import Header from "./components/header"
import { RenderRoutes } from "./routes";
import Navigation from "./components/nav";
import IntroOverlay from './components/introOverlay'
import { withRouter } from 'react-router-dom';
import Player from './components/player'
import CustomCursor from './components/CursorCustom'
import { Helmet, HelmetProvider } from "react-helmet-async";

const ComponentToHide = (props, dimensions) => {
  const { location } = props;
  console.log("Dimendsion: ", location);
  if (location.pathname == "/" || location.pathname == "/mood") {
    return null;
  }
  return (
    <Header dimensions={dimensions} />
  )
}

const ComponentThatHides = withRouter((props, dimensions) => (
  ComponentToHide(props, dimensions)
));




function debounce(fn, ms) {
  let timer;
  return () => {
    clearTimeout(timer);
    timer = setTimeout(() => {
      timer = null;
      fn.apply(this, arguments);
    }, ms);
  }
}


const tl = gsap.timeline();

/*METTRE A 4 loadingpercentage */
const HomeAnimation = (completeAnimation) => {
  tl.to(".loading-percentage", 4, {
    right: 0,
    ease: 'expo.inOut',
  }).to(".overlay-top", 1.6, {
    height: 0,
    ease: 'expo.inOut',
  }).to('.intro-overlay', 0, { css: { display: "none" } });
};


function App() {

  const [animationComplete, setAnimationComplete] = useState(false);
  const completeAnimation = () => {
    setAnimationComplete(true);
  }

  useEffect(() => {
    HomeAnimation(completeAnimation);
  }, [])

  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth
  });


  useEffect(() => {
    let vh = dimensions.height * .01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
    gsap.to("body", 0, { css: { visibility: "visible" } });



    const debounceHandleResize = debounce(function handleResize() {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth
      });
    }, 1000)

    window.addEventListener("resize", debounceHandleResize);

    return () => {
      window.removeEventListener("resize", debounceHandleResize);
    }

  })


  return (
    <HelmetProvider>
      <Helmet>
        <title>Mazlum Küsne - Photographie de rue</title>
        <meta
          name="description"
          content="Mazlum Küsne est un photographe Suisse basé à Fribourg. Visitez son site web pour découvrir ses oeuvres."
        />;
        {/* TWITTER */}
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:image" content="https://mazlum-site.vercel.app/android-chrome-512x512.png" />
        <meta
          name="twitter:title"
          content="Mazlum Küsne est un photographe Suisse basé à Fribourg. Visitez son site web pour découvrir ses oeuvres."
        />
        <meta name="twitter:site" content="www.mazlumkusne.ch" />
        <meta
          name="twitter:description"
          content="Mazlum Küsne est un photographe Suisse basé à Fribourg. Visitez son site web pour découvrir ses oeuvres."
        />
        {/* Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:url" content="www.mazlumkusne.ch" />
        <meta
          property="og:title"
          content="Mazlum Küsne est un photographe Suisse basé à Fribourg. Visitez son site web pour découvrir ses oeuvres."
        />
        <meta
          property="og:description"
          content="Mazlum Küsne est un photographe Suisse basé à Fribourg. Visitez son site web pour découvrir ses oeuvres."
        />
        <meta
          property="og:image:secure_url"
          content="https://mazlum-site.vercel.app/android-chrome-512x512.png"
        />
        <meta
          property="og:image"
          content="https://mazlum-site.vercel.app/android-chrome-512x512.png"
        />

      </Helmet>
      <CustomCursor />
      {animationComplete === false ? <IntroOverlay /> : ""}
      <ComponentThatHides dimensions={dimensions} />
      <div className='App'>
        <RenderRoutes />
      </div>
      <Player />
      <Navigation />
    </HelmetProvider>
  );
}

export default App;
