import player from './player';
import theme from './theme';
//importer autres reducer ici
import { combineReducers } from 'redux';

const allReducers = combineReducers({
    player: player,
    theme: theme
})

export default allReducers;