import React, { useEffect, useRef, useState } from "react";
import useSound from 'use-sound';
import musicRap from '../assets/rap.mp3';
import FeatherIcon from 'feather-icons-react';
import { setMusicState, setThemeState } from '../actions'
import { useDispatch, useSelector } from 'react-redux'
import { motion } from "framer-motion"
import { lightTheme, darkTheme } from "./Theme"
import {ThemeProvider} from "styled-components";
import { GlobalStyles } from "./GlobalStyles";
import gsap from "gsap";

const transition = { repeat: Infinity, repeatType: "reverse", duration: 1.3, delay: 0.6, ease: [.43, .23, .13, .96] }
const transition2 = { repeat: Infinity, repeatType: "reverse", duration: 1.3, delay: 0.4, ease: [.43, .13, .23, .96] }
const transition3 = { repeat: Infinity, repeatType: "reverse", duration: 1.1, delay: 0.8, ease: [.43, .13, .23, .96] }
const transition4 = { repeat: Infinity, repeatType: "reverse", duration: 1.3, delay: 0.5 }
const transitionStop = { duration: 1.5, ease: [.43, .13, .23, .96] }

const Widget = () => {

    const dispatch = useDispatch();
    const musicState = useSelector(state => state.player.musicState);
    const currentTheme = useSelector(state => state.theme.theme);

    const PlayPause = () => {
        dispatch(setMusicState(!musicState));
    };

    const variants = {
        play: { width: 20 },
        paused: { width: 3 },
    }

    const variants2 = {
        play: { width: 14 },
        paused: { width: 3 },
    }
  

    const tl = gsap.timeline();

    const themeToggler = () => {
      if(currentTheme === 'light'){
        dispatch(setThemeState('dark'));

        tl.to(".iconMoon", 0.4, {
            css: { top : '40px'  },
            ease: 'expo.inOut',
            delay: 0,
          }).to(".iconMoon", 0, {
            delay: 0,
            css: { display: "none", top: '-40px'},
            ease: 'expo.inOut',
          }).to(".iconSun", 0, {
            delay: 0,
            css: { display: "block"},
            ease: 'expo.inOut',
          }).to(".iconSun", 0.4, {
            delay: 0,
            css: { top : '0px'  },
            ease: 'expo.inOut',
          });

      }else{
        dispatch(setThemeState('light'));

        tl.to(".iconSun", 0.4, {
            css: { top : '40px'  },
            ease: 'expo.inOut',
          }).to(".iconSun", 0, {
            css: { display: "none", top: '-40px'},
            ease: 'expo.inOut',
          }).to(".iconMoon", 0, {
            css: { display: "block"},
            ease: 'expo.inOut',
          }).to(".iconMoon", 0.4, {
            css: { top : '0px'  },
            ease: 'expo.inOut',
          });
      }

    }

  



    return (
        <>
        <ThemeProvider theme={currentTheme === 'dark' ? lightTheme : darkTheme}>
        <GlobalStyles/>
            <div className='border'>
                <div className='player' onClick={PlayPause}>
                    <motion.span animate={musicState ? "play" : "paused"} variants={variants} transition={musicState ? transition : transitionStop}></motion.span>
                    <motion.span animate={musicState ? "play" : "paused"} variants={variants2} transition={musicState ? transition2 : transitionStop}></motion.span>
                    <motion.span animate={musicState ? "play" : "paused"} variants={variants} transition={musicState ? transition3 : transitionStop}></motion.span>
                    <motion.span animate={musicState ? "play" : "paused"} variants={variants2} transition={musicState ? transition4 : transitionStop}></motion.span>
                </div>
                <div className='screenMode' onClick={themeToggler}>
                    <motion.div>
                    <FeatherIcon className="iconMoon" icon="moon" />
                    <FeatherIcon className="iconSun" icon="sun" />
                    </motion.div>
                </div>
            </div>
            </ThemeProvider>
        </>
    );
};

export default Widget;