import React from 'react';
import { NavLink } from "react-router-dom";
import FeatherIcon from 'feather-icons-react';
import { motion } from 'framer-motion';
import Button from "../components/Button";

const transition = { duration: .6, ease: [.43, .13, .23, .96] }
const Lasttransition = { duration: .8, ease: [.43, .13, .23, .96] }


const Question = () => {

    return (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { delay: 1.2, ...transition } }} exit={{ opacity: 0, height: 0, transition: { delay: 1.2, ...Lasttransition } }} className="card-question">
            <motion.div exit={{ opacity: 0, height: 0, transition: { delay: 1.4, ...transition } }} className="icon-question" ><FeatherIcon icon="headphones" /></motion.div>
            <motion.p exit={{ opacity: 0, height: 0, transition: { delay: 0.8, ...transition } }}>Vivez une meilleure expérience avec votre casque audio.</motion.p>
            <motion.div exit={{ opacity: 0, height: 0, transition: { delay: .3, ...transition } }} className="question-btn">
                <Button link="/mood">Entrer</Button>
            </motion.div>
        </motion.div>
    );
}

export default Question;