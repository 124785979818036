import React, { useRef, useEffect } from 'react';
import { TweenLite, gsap } from 'gsap'
import { NoToneMapping } from 'three';

const transition = { duration: .6, ease: [.43, .13, .23, .96] }

const opacity = .2;

const CustomCursor = () => {


    var ref = useRef();
    useEffect(() => {
        const tl = gsap.timeline();

        if (window.innerWidth < 768) {
            tl.set(".ball", {
                opacity: 0,
            })
        }


        gsap.set(".ball", { xPercent: -50, yPercent: -50 });
        var ball = document.querySelector(".ball");

        var pos = { x: window.innerWidth / 2, y: window.innerHeight / 2 };
        var mouse = { x: pos.x, y: pos.y };
        var speed = 0.1;

        var fpms = 60 / 800;

        var xSet = gsap.quickSetter(ball, "x", "px");
        var ySet = gsap.quickSetter(ball, "y", "px");

        window.addEventListener("mousemove", e => {
            if (window.innerWidth < 768) {
                mouse.x = e.x;
                mouse.y = e.y;
                tl.to(".ball", 0.4, {
                    opacity: opacity,
                    ease: 'expo.inOut',
                    scale: 2.1,
                }).to(".ball", 1.2, {
                    delay: 0.1,
                    opacity: 0,
                    ease: 'Power4.easeOut',
                    scale: 1,
                });
            } else {
                mouse.x = e.x;
                mouse.y = e.y;
            }

        });

        gsap.ticker.add((time, deltaTime) => {

            var delta = deltaTime * fpms;
            var dt = 1.0 - Math.pow(1.0 - speed, delta);

            pos.x += (mouse.x - pos.x) * dt;
            pos.y += (mouse.y - pos.y) * dt;
            xSet(pos.x);
            ySet(pos.y);
        });

    }, [ref])




    return (
        <div ref={ref} className="ball" style={{
            width: "50px",
            height: "50px",
            zIndex: 5,
            position: "fixed",
            top: 0,
            left: 0,
            opacity: opacity,
            backgroundColor: "#E2E1DD",
            pointerEvents: "none",
            borderRadius: "50%",
            transformOrigin: 'center',
        }}></div>
    );
}

export default CustomCursor;