import musicRap from '../assets/rap.mp3';
import musicClassic from '../assets/classic.mp3';

const initialState = {
    music: null,
    musicState: null
};


// Use the initialState as a default value
const playerReducer = (state = initialState, action) => {
    // The reducer normally looks at the action type field to decide what happens
    switch (action.type) {
        case 'SET_MUSIC':
            let musicSelected = null;
            if (action.payload.music == 2) {
                musicSelected = musicClassic;
            } else {
                musicSelected = musicRap
            }
            return { ...state, music: musicSelected };
        case 'SET_MUSIC_STATE':
            return { ...state, musicState: action.payload.state };
        // Do something here based on the different types of actions
        default:
            // If this reducer doesn't recognize the action type, or doesn't
            // care about this specific action, return the existing state unchanged
            return state
    }
}

export default playerReducer;