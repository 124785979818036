import React from 'react';
import { NavLink } from "react-router-dom";
import { motion } from 'framer-motion';
import $ from "jquery";
import gsap from "gsap";

const transition = { duration: 1.2, ease: [.43, .13, .23, .96] }
const Lasttransition = { duration: .8, ease: [.43, .13, .23, .96] }

const Button = (props) => {

    $(document).ready( function(){
        $('body').on( 'mouseenter' , '.btn', function(e){
          gsap.to( this, {duration: 0.3, height: 250, width: 250} );
          gsap.to( $(this).find('.btnSticky'), {duration: 0.3, scale:1.1} );
          gsap.to( $('body').find('.ball'), {duration: 0.3, scale:0} );
        });
        
        $('.btn').mouseleave( function(e){
          gsap.to( this , {duration: 0.3, height: 250, width: 250} );
          gsap.to( $(this).find('.btnSticky') , {duration: 0.3, scale:1, x: 0, y: 0} );
          gsap.to( $('body').find('.ball'), {duration: 0.3, scale:1} );
        });
      
        $('.btn').mousemove( function(e){   
          parallaxIt(e, 80);
        });

      });
                        
      function parallaxIt(e, movement){
        var $this = $(e.target);
        var relX = e.pageX - $this.offset().left;
        var relY = e.pageY - $this.offset().top;
        
        gsap.to( $this.find('.btnSticky'), { 
          duration: 0.3,
          x: (relX - $this.width()/2) / $this.width() * movement,
          y: (relY - $this.height()/2) / $this.height() * movement,
          ease: "power2.easeOut"
        });
      }
    return (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1, transition: { delay: 1.2, ...transition } }} exit={{ opacity: 0, height: 0, transition: { delay: 1.2, ...Lasttransition } }} >
            <NavLink className="btn" to={props.link} exact>
                <div className="btnSticky">
                    <div> {props.children}</div>
                </div>
            </NavLink>
        </motion.div>
    );
}

export default Button;